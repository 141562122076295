/** @jsx jsx */
import { jsx } from 'theme-ui'

import { appendQuery, queryList } from '@u/url'
import { path } from '@u/obj'

import { useSelector } from 'react-redux'
import { host } from '@r/app-selectors'

const sameHost = (a, b) => {
  try {
    return a === new URL(b).host
  } catch (e) {
    /**
     * Assume that if anything goes wrong we are on the same host
     * e.g. `/same-host-path`
     * Therefor assume true and don't preserve query params
     */
    return true
  }
}

export const FormHiddenFields = ({ action }) => {
  const appHost = useSelector(host)
  const query = useSelector(s => path(['app', 'location', 'query'], s))

  const fields = queryList(appendQuery(action, query))

  // Do not add hidden input fields for same domain clickouts
  if (sameHost(appHost, action)) return null

  return (
    <For each='param' of={fields} index='i'>
      <input type='hidden' name={param.param} value={param.value} />
    </For>
  )
}
