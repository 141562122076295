/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { List, ListItem } from '@uswitch/trustyle.list'
import Markdown from 'markdown-to-jsx'

const cs = (...c) => c.filter(i => i).join(' ')
const styles = {
  fontFamily: 'body',
}

const MarkdownList = ({ ordered, ...props }) => {
  return (
    <List listType={ordered ? 'numeric' : 'bullet'} {...props}>
      {props.children}
    </List>
  )
}

const MarkdownHeading = props => {
  const Heading = Styled[`h${props.level}`]
  return <Heading {...props}>{props.children}</Heading>
}

export const Copy = ({ copy, as = Styled.p, children, className, options = {}, ...props }) => (
  <Choose>
    <When condition={options.markdown}>
      <Markdown
        sx={styles}
        children={children || copy}
        className={cs(options.className, className)}
        overrides={{
          paragraph: { component: Styled.p },
          link: { component: Styled.a },
          list: { component: MarkdownList },
          listItem: { component: ListItem },
          heading: { component: MarkdownHeading },
        }}
      />
    </When>
    <Otherwise>
      {jsx(as, {
        className: cs(options.className, className),
        sx: styles,
        children: children || copy,
      })}
    </Otherwise>
  </Choose>
)

export default Copy
