/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ButtonLink } from '@uswitch/trustyle.button-link'
import connectEntity from '@h/connect-entity'
import ModuleWrapper from '@h/module-wrapper'

const ConnectedButton = connectEntity((state, props) => {
  return {
    ...props,
    children: props.text,
  }
})(ButtonLink)

export default ({ entries = [], options = {} }) => {
  const { spacingTop = 'lg', spacingBottom = 'lg' } = options

  return (
    <div sx={{ textAlign: ['left', 'right'] }}>
      <ModuleWrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
        <div
          sx={theme => ({
            display: ['block', 'inline-flex'],
            flexDirection: 'row-reverse',
            marginLeft: -theme.space.sm,
            marginBottom: -theme.space.sm,
          })}
        >
          <For index='key' each='entry' of={entries}>
            <ConnectedButton
              key={key}
              entity={entry}
              sx={{ marginBottom: 'sm', marginLeft: 'sm' }}
            />
          </For>
        </div>
      </ModuleWrapper>
    </div>
  )
}
