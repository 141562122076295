import React from 'react'
import HeroCard from '@uswitch/trustyle.hero-card'
import { ContentfulModules } from '@h/modules.jsx'

const HeroCardCompound = props => {
  return (
    <HeroCard title={props.title} ctaText={props.ctaText}>
      <ContentfulModules modules={props.links} />
    </HeroCard>
  )
}

export default HeroCardCompound
