/** @jsx jsx */
import { jsx } from 'theme-ui'
import ImgixImg from '@e/imgix-img.connected'
import TestimonialCard from '@uswitch/trustyle.testimonial-card'
import StarRating from '@e/star-rating'

// We are connecting the nested image asset to the card data
export default props => {
  const ratingImg = <StarRating value={Number(props.customerRating)} />
  const authorImg = props.authorImage ? <ImgixImg entity={props.authorImage} /> : null
  const cardProps = { ...props, authorImg, ratingImg }
  return <TestimonialCard {...cardProps} />
}
