export const queryList = (url = '') => {
  const [, search = ''] = url.split('?')

  return search.split('&').reduce((acc, it) => {
    const [param, value = ''] = it.split('=')
    return acc.concat({ param, value })
  }, [])
}

export const appendQuery = (url = '', query = {}) => {
  if (!url.length) return ''

  const [domain, search = ''] = url.split('?')

  if (!search.length) return ''

  const paramsObj = search.split('&').reduce((acc, it) => {
    const [param, value = ''] = it.split('=')

    if (!param) return acc

    return { [param]: value, ...acc }
  }, query || {})

  const paramsString = Object.entries(paramsObj)
    .map(([param, value]) => `${param}=${value}`)
    .join('&')

  return paramsString.length ? `${domain}?${paramsString}` : domain
}

export const withTrailingSlash = s => s.replace(/\/?$/, '/')
export const withoutTrailingSlash = s => s.replace(/\/?$/, '')
