/** @jsx jsx */
import { jsx, Styled, useThemeUI } from 'theme-ui'
import { Icon } from '@uswitch/trustyle.icon'

const getStyles = element => `compounds.link-with-label.${element}`

export default ({ text, title, label, description, icon, action }) => {
  const { theme } = useThemeUI()

  return (
    <div sx={{ variant: getStyles('wrapper') }}>
      <If condition={label || description}>
        <div sx={{ variant: getStyles('column') }}>
          <Styled.h5>{label}</Styled.h5>
          <Styled.p>{description}</Styled.p>
        </div>
      </If>
      <Styled.a
        className={action === '/account/signin' ? 'js-account-origin js-open-o-signin' : ''}
        href={action}
        title={title}
        sx={{ variant: getStyles('anchor') }}
      >
        <If condition={icon}>
          <Icon glyph={icon} size={theme.compounds['link-with-label']?.icon?.size || 'md'} />
        </If>
        <span>{text}</span>
      </Styled.a>
    </div>
  )
}
