/** @jsx jsx */
import { jsx } from 'theme-ui'
import connect from '@h/connect-redux'

import Copy from '@c/copy'
import dayjs from '@u/date'
import ImgixImg from '@e/imgix-img.connected'
import { createdAt } from '@r/contentful-selectors'

const Author = ({ name, role, photo = {}, publishedAt }) => (
  <div
    sx={{
      variant: ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
      marginY: [38, 47, 67],
    }}
  >
    <div
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row', 'row'],
        width: '100%',
        paddingTop: 'lg',
        borderTop: '1px solid',
        borderColor: 'light-3',
      }}
    >
      <div sx={{ display: 'flex' }}>
        <ImgixImg
          entity={photo}
          width={68}
          height={68}
          sx={{
            width: 68,
            height: 68,
            borderRadius: ({ radii }) => `${radii.lg}px 0`,
          }}
        />

        <div
          sx={{
            marginLeft: 10,
            borderRadius: 'sm',
            overflow: 'hidden',
          }}
        >
          <div
            sx={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '26px 26px 0 0',
              borderColor: t => `${t.colors['accent-2']} transparent`,
            }}
          />
        </div>
      </div>

      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingTop: ['md', 0, 0],
        }}
      >
        <Copy
          as='span'
          sx={{
            color: 'brand',
            lineHeight: 'sm',
            marginBottom: 'xxs',
            fontSize: 'base',
            fontWeight: 'base',
          }}
        >
          {name}
        </Copy>
        <Copy
          as='span'
          sx={{
            lineHeight: 'md',
            fontSize: 'sm',
            color: 'dark-2',
          }}
        >
          <If condition={role}>{role}</If>
          <If condition={role && publishedAt}>{' - '}</If>
          <If condition={publishedAt}>{dayjs(publishedAt).format('MMM Do YYYY')}</If>
        </Copy>
      </div>
    </div>
  </div>
)

export default connect(s => ({ publishedAt: createdAt(s) }))(Author)
