/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import { meta } from '@r/app-selectors'
import ReadMoreCard from '@uswitch/trustyle.read-more-card'
import { useEntity } from '@h/use-entity'
import { Palette } from '@uswitch/trustyle-utils.palette'

const contentfulVariantMapping = {
  'Read More': 'default',
  Cupcard: 'cupcard',
}

const ConnectedReadMoreCard = ({ displayText, url, variant, ...props }) => {
  const href = useEntity(url).url
  const homePath = useSelector(state => meta(state)?.breadcrumbs?.homePath) || ''

  return (
    <ReadMoreCard
      text={displayText}
      href={href[0] === '/' ? href : `${homePath}/${href}`}
      variant={contentfulVariantMapping[variant]}
      {...props}
    />
  )
}

export default props => (
  <Palette as={ConnectedReadMoreCard} px={{ backgroundColor: 'featureColor' }} {...props} />
)
