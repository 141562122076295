/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import connectEntity from '@h/connect-entity'
import { ModulesToReactComponents } from '@h/modules'
import ImgixImg from '@e/imgix-img.connected'

import { Container } from '@uswitch/trustyle.flex-grid'
import AuthorProfile from '@uswitch/trustyle.author-profile'

const Profile = connectEntity((state, props) => {
  const authorImage = <ImgixImg entity={props.image} />

  const config = useSelector(state => state?.app?.meta?.authorProfileConfig) || {}

  return {
    ...props.profileProps,
    name: props.name,
    role: props.role,
    twitterLink: props.twitterLink,
    linkedInLink: props.linkedInLink,
    email: props.email,
    authorUrl: props.url,
    showDetails: config.showDetails,
    authorImage: authorImage,
    socialsHrefTarget: config.socialsHrefTarget,
  }
})(AuthorProfile)

export default props => {
  const bio =
    props?.bio == null || typeof props.bio === 'string' || props.bio instanceof String
      ? props?.bio
      : ModulesToReactComponents(props.bio.content)

  return (
    <Container
      sx={{
        img: {
          height: '100%',
          objectFit: 'cover',
        },
      }}
    >
      <Profile entity={props.author} profileProps={{ ...props, bio }} />
    </Container>
  )
}
