/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'

import { Icon } from '@e/icon'
import Img from '@e/imgix-img.connected'
import Copy from '@c/copy'
import { Palette } from '@uswitch/trustyle-utils.palette'
import { LinkWrapper } from '@e/link'

const Profile = ({ name, role, company, photo, className }) => (
  <div
    className={className}
    sx={{
      variant: ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
    }}
  >
    <div
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <div sx={{ marginRight: 'md' }}>
        <Choose>
          <When condition={photo}>
            <Img
              entity={photo}
              width={144}
              height={144}
              sx={{ variant: 'modules.profile.img', objectFit: 'cover' }}
            />
          </When>
          <Otherwise>
            <Palette as={Icon} size='xl' sx={{ fill: 'light-3' }} px={{ fill: 'accentColor' }} />
          </Otherwise>
        </Choose>
      </div>

      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Copy
          as='span'
          sx={{
            color: 'brand',
            lineHeight: 'sm',
            marginBottom: 'xxs',
            fontSize: 'base',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          {name}
        </Copy>
        <Copy
          as='span'
          sx={{
            lineHeight: 'md',
            fontSize: 'sm',
            color: 'dark-2',
          }}
        >
          <If condition={role}>
            <span>{role}</span>
          </If>
          <br />
          <If condition={company}>
            <Styled.b>{company}</Styled.b>
          </If>
        </Copy>
      </div>
    </div>
  </div>
)

const ProfileWithLinkLogic = ({ link, ...rest }) => (
  <Choose>
    <When condition={link}>
      <LinkWrapper href={link}>
        <Profile {...rest} />
      </LinkWrapper>
    </When>
    <Otherwise>
      <Profile {...rest} />
    </Otherwise>
  </Choose>
)

export default ProfileWithLinkLogic
