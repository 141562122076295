import React, { useState } from 'react'

import { POSTCODE_REGEX } from '../../constants/regex'
import { FormHiddenFields } from '@h/form-hidden-fields'

const validPostcode = str => {
  return (
    str
      .trim()
      .toUpperCase()
      .match(POSTCODE_REGEX) != null
  )
}

const PostcodeForm = () => {
  const [postcode, setPostcode] = useState('')
  const [invalidPostcode, isPostcodeInvalid] = useState(false)
  const action = '/gas-electricity/drop'

  const validatePostcode = event => {
    if (!validPostcode(postcode)) {
      event.preventDefault()
      isPostcodeInvalid(true)
    }
  }

  return (
    <div className='us-grid-row'>
      <div className='us-col-10 us-col-lg-9 us-col-xlg-7'>
        <form
          className='submit-postcode drop-form js-form'
          onSubmit={event => validatePostcode(event)}
          action={action}
        >
          <FormHiddenFields action={action} />

          <If condition={invalidPostcode}>
            <div className='us-validation us-validation-error'>
              <div className='us-validation__icon' />
              <div className='us-validation__message'>Please enter a valid UK postcode</div>
            </div>
          </If>
          <label>
            <div className='us-input-group us-input-group--blocked'>
              <span className='us-input-group__box'>
                <svg className='us-icon--home us-icon--small us-icon--inputgrey us-icon--notext'>
                  <use xlinkHref='/icons.svg#icon-home' />
                </svg>
              </span>
              <input
                aria-label='Your postcode'
                type='text'
                name='postcode'
                placeholder='Your postcode'
                required
                onChange={event => setPostcode(event.target.value)}
                className='us-form-input postcode large'
              />
            </div>
          </label>
          <input
            type='submit'
            value='Compare energy deals'
            className='us-btn us-btn--primary us-btn--blocked us-btn--large us-margin-top js-customer-form-button'
          />
        </form>
      </div>
    </div>
  )
}

export default PostcodeForm
