import connectEntity from '@h/connect-entity'
import ImgixImg from '@e/imgix-img'

import { path } from '@u/obj'

export default connectEntity((state, { description, file, ...props }) => ({
  alt: description,
  src: path(['url'], file),
  ...props,
}))(ImgixImg)

export const ProviderImage = connectEntity((state, props) => ({
  alt: path(['name'], props),
  src: path(['image'], props),
}))(ImgixImg)
