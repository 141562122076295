import React from 'react'

import Copy from '@c/copy'
import ImgixImg from '@e/imgix-img.connected'
import PostcodeForm from '@c/postcode-form'

const defaultHeroDescriptionOptions = {
  options: {
    className: 'us-hero-description us-standfirst us-margin-bottom',
  },
}

const Hero = ({ header, description, imgLarge, imgSmall, showForm }) => (
  <div className='us-hero'>
    <div className='us-hero-container'>
      <If condition={imgLarge}>
        <ImgixImg entity={imgLarge} className='hero-image' />
      </If>

      <div className='us-grid-row start-box'>
        <div className='us-col-md-7'>
          <If condition={header}>
            <h2 className='us-hero-title'>{header.fields.copy}</h2>
          </If>

          <If condition={description}>
            <Copy {...Object.assign({}, defaultHeroDescriptionOptions, description.fields)} />
          </If>

          <If condition={showForm}>
            <PostcodeForm />
          </If>
        </div>
      </div>
    </div>
  </div>
)

export default Hero
