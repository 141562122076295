/** @jsx jsx */
import { jsx } from 'theme-ui'
import StarRating from 'eevee-components/star-rating'
import ImgixImg from '@e/imgix-img.connected'

export default ({ starNumber, value, EndIcon, variant }) => (
  <StarRating
    starsNumber={starNumber}
    variant={variant}
    EndIcon={<ImgixImg entity={EndIcon} />}
    value={value}
  />
)
