/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { path } from '@u/obj'
import { iconsPath } from '@r/app-selectors'
import connect from '@h/connect-redux'

export const Icon = ({ name, className, size = 'md', iconsPath = '/icons.svg' }) => {
  const { theme } = useThemeUI()
  const s = path(['sizes', 'icon', size], theme) || 32

  return (
    <svg viewBox={`0 0 ${s} ${s}`} width={s} height={s} className={className}>
      <use xlinkHref={`${iconsPath}#icon-${name}`} />
    </svg>
  )
}

export default connect(state => ({
  iconsPath: iconsPath(state),
}))(Icon)
