/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import loadable from '@loadable/component'
import { Icon } from '@uswitch/trustyle.icon'

const ButtonLink = loadable(() => import('@e/button-link'))
const Button = loadable(() => import('@e/button-default'))
const ModalButton = loadable(() => import('@c/modal-button'))

export default props => {
  const { href, link, modalContent, beforeIcon, afterIcon, variant, text } = props
  const { theme } = useThemeUI()

  const hrefLink = href || link

  const getButtonVariant = element =>
    `elements.buttons.${variant ? `variants.${variant}.${element}` : element}`

  const iconSize = theme.elements.buttons?.icon?.size || 22
  const iconColor = theme.elements.buttons?.icon?.color || 'white'

  const ButtonChildren = (
    <Fragment>
      <If condition={beforeIcon}>
        <span sx={{ variant: getButtonVariant('beforeIcon') }}>
          <Icon size={iconSize} color={iconColor} glyph={beforeIcon} direction='left' />
        </span>
      </If>
      {text}
      <If condition={afterIcon}>
        <span sx={{ variant: getButtonVariant('afterIcon') }}>
          <Icon size={iconSize} color={iconColor} glyph={afterIcon} direction='right' />
        </span>
      </If>
    </Fragment>
  )

  return (
    <Choose>
      <When condition={modalContent}>
        <ModalButton {...props} />
      </When>
      <When condition={hrefLink}>
        <ButtonLink {...props}>{ButtonChildren}</ButtonLink>
      </When>
      <Otherwise>
        <Button {...props}>{ButtonChildren}</Button>
      </Otherwise>
    </Choose>
  )
}
